html {
  width: 100%;
  height: 100%;
  margin: 0;
}

body {
  width: 100%;
  height: 100%;
  color: #0f0;
  background-color: #000;
  margin: 0;
  position: absolute;
}

.fps {
  opacity: .1;
  margin: 5px;
  position: absolute;
  top: 0;
  right: 0;
}

#canvas {
  opacity: .3;
}

.shrug {
  color: #0f0;
  text-align: center;
  white-space: nowrap;
  margin: 0;
  font-size: 7em;
  animation: breathing 5s ease-out infinite;
  position: fixed;
  top: 30%;
  left: 0;
  right: 0;
  transform: translateY(-50%);
}

@keyframes breathing {
  0% {
    opacity: .1;
    transform: scale(.3);
  }

  25% {
    opacity: 1;
    transform: scale(1);
  }

  60% {
    opacity: 0;
    transform: scale(.3);
  }

  100% {
    opacity: 0;
    transform: scale(.3);
  }
}

/*# sourceMappingURL=index.364b57a4.css.map */
