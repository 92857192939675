html {
    width: 100%;
    height: 100%;
    margin: 0;
}

body {
    width: 100%;
    height: 100%;
    background-color: black;
    color: #0f0;
    position: absolute;
    margin: 0;
}

.fps {
    position: absolute;
    right: 0;
    top: 0;
    opacity: 0.1;
    margin: 5px;
}

#canvas {
    opacity: 0.3;
}

.shrug {
    color: #0f0;
    font-size: 7em;
    position: fixed;
    top: 30%;
    transform: translateY(-50%);
    margin: 0;
    left: 0;
    right: 0;
    text-align: center;

    white-space: nowrap;
    
    animation: breathing 5s ease-out infinite normal;
}

@keyframes breathing {
  0% {
    opacity: 0.1;
    transform: scale(0.3);
  }

  25% {
    opacity: 1;
    transform: scale(1);
  }

  60% {
    opacity: 0;
    transform: scale(0.3);
  }

  100% {
    opacity: 0;
    transform: scale(0.3);
  }
}